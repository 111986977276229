<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <a-select
          :options="[
            { value: null, label: 'Pilih Type' },
            { value: 'sales', label: 'Sales' },
            { value: 'stock', label: 'SO' },
          ]"
          class=" mt-2 mr-2"
          style="width: 300px;"
          placeholder="Semua Type"
          v-model:value="type"
        ></a-select>

        <a-select
          :options="[
            { value: null, label: 'Pilih Status' },
            { value: 1, label: 'Sukses' },
            { value: 0, label: 'Gagal' },
          ]"
          class=" mt-2 mr-2"
          style="width: 300px;"
          placeholder="Semua Status"
          v-model:value="status"
        ></a-select>

        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          v-model:value="begin_date"
          :disabled-date="disabledStartDate"
          format="DD MMMM YYYY"
          placeholder="Start Date"
        />

        <a-date-picker
          style="width: 300px;"
          class="mr-2 mt-2"
          v-model:value="end_date"
          :disabled-date="disabledEndDate"
          format="DD MMMM YYYY"
          placeholder="End Date"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button
          class="mt-2 mr-2"
          type="primary"
          title="cari"
          @click="search"
          :loading="isFetching"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
        <a-button
          class="ml-2 mr-2"
          type="primary"
          title="syncron manual"
          @click="
            () => {
              formState = {}
              modal2Visible = true
              formState.title_state = 'Synchronize'
            }
          "
        >
          <i class="fa fa-refresh" aria-hidden="true"></i>
        </a-button>
      </div>
    </div>
    <!-- <div class="row justify-content-end m-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button
          type="primary"
          stylea="width: 200px; background-color: #3acf41; border: none;"
          title="Download Xls"
          @click="fetchXlsx"
          :loading="isDownloading"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body mt-2">
            <div class="table-responsive ">
              <a-table
                :columns="columns"
                :data-source="data"
                size="small"
                :row-selection="rowSelection"
                :pagination="{
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                  showTotal: (total, range) =>
                    `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                  total: meta.totalCount,
                  pageSize: meta.perPage,
                  current: meta.currentPage,
                }"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                bordered
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template
                  #filterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                >
                  <div class="custom-filter-dropdown">
                    <a-input
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                      @pressEnter="() => handleSearch(selectedKeys, confirm)"
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="() => handleSearch(selectedKeys, confirm)"
                      >Search</a-button
                    >
                    <a-button
                      size="small"
                      style="width: 90px"
                      @click="() => handleReset(clearFilters)"
                      >Reset</a-button
                    >
                  </div>
                </template>
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>
                <template #customer="{ text }">
                  <span v-if="searchText">
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                      <mark
                        v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
                  </template>
                </template>
                <template #no="{ index }">
                  <span>
                    {{ startRow + index }}
                  </span>
                </template>
                <template #progress="{ text: bar }">
                  <div class="progress">
                    <div
                      :class="['progress-bar', bar.color]"
                      :style="{ width: bar.value + '%' }"
                      role="progressbar"
                    ></div>
                  </div>
                </template>
                <template #value="{ text }">
                  <span class="font-weight-bold">{{ text }}</span>
                </template>
                <template #id="{ text }">
                  <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                </template>
                <template #total="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #tax="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #shipping="{ text }">
                  <span>${{ text }}</span>
                </template>
                <template #status="{ text }">
                  <a-tag v-if="text === 'sukses'" color="#108ee9">Sukses</a-tag>
                  <a-tag v-else color="grey">Gagal</a-tag>
                </template>
                <template #action="{record}">
                  <span>
                    <a-tooltip title="Syncron">
                      <a
                        href="javascript: void(0);"
                        class="btn btn-sm btn-light"
                        @click="syncrons(record)"
                      >
                        <small>
                          <i class="fa fa-refresh"></i>
                        </small>
                      </a>
                    </a-tooltip>
                  </span>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body"><vb-controls-pagination /></div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12">
        <div class="card-placeholder">
          <div class="card-body"><vb-controls-pagination-2 /></div>
        </div>
      </div>
    </div>
    <a-modal
      v-model:visible="modal2Visible"
      :title="formState.title_state"
      :destroy-on-close="true"
      width="1000px"
      :mask-closable="false"
    >
      <a-form :wrapper-col="{ span: 14 }" :scroll-to-first-error="true" class="myform">
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Tipe API"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
            >
              <a-select 
                v-model:value="syncType"
                placeholder="Pilih Tipe"
                mode="multiple"
                allow-clear
                option-filter-prop="label"
                option-label-prop="label"
                @search="onSearch"
                :options="[
                  { value: 'stock', label: 'SO' },
                  { value: 'sales', label: 'Sales' },
                ]">
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :md="12" :sm="24">
            <a-form-item
              label="Tgl Awal"
              label-align="left"
              :label-col="{ md: { span: 8 }, sm: { span: 4 } }"
              :wrapper-col="{ md: { span: 12 - 8 }, sm: { span: 24 - 4 } }"
              allow-clear
            >
            <a-date-picker
              style="width: 300px;"
              v-model:value="sync_start_date"
              format="DD MMMM YYYY"
              placeholder="Start Date"
            />
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="24">
            <a-form-item
              label="Tgl Akhir"
              label-align="left"
              :label-col="{ md: { span: 7 }, sm: { span: 4 } }"
              :wrapper-col="{ md: { span: 12 - 7 }, sm: { span: 24 - 4 } }"
              allow-clear
            >
            <a-date-picker
              style="width: 300px;"
              v-model:value="sync_end_date"
              format="DD MMMM YYYY"
              placeholder="End Date"
            />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row class="form-row">
          <a-col :sm="24">
            <a-form-item
              label="Result"
              label-align="left"
              :label-col="{ sm: { span: 4 } }"
              :wrapper-col="{ sm: { span: 24 - 4 } }"
              allow-clear
            >
            </a-form-item>
          </a-col>
          <a-col :sm="24">
            <div class="card">
              <div class="card-body" style="background: #e3e3e3;">
                <pre id="resultApi"></pre>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <a-button
          key="submit"
          type="primary"
          @click="syncData"
          :loading="isSync"
          >{{formState.title_state}}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, reactive, toRefs, computed } from 'vue'

import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'

import qs from 'qs'
import moment, { Moment } from 'moment'
import acolumns from './columns'

import { useDisabledDate } from '@/composables/DisabledDate'

// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default defineComponent({
  components: {},
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)

    const status = ref(null)
    const type = ref(null)

    const modal1Visible = ref(false)
    const modal2Visible = ref(false)

    const q = ref('')
    const dateFormat = 'YYYY-MM-DD';
    const formState = ref({})
    const syncType = ref([])
    const syncFunction = ref([])
    const sync_start_date = ref(Moment)
    const sync_end_date = ref(Moment)

    const errorMessage = ref(null)
    const {
      startDate,
      endDate,
      start_date: begin_date,
      end_date,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
    } = useDisabledDate()

    columns.value = acolumns

    const params = ref({})
    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        begin_date: startDate.value,
        end_date: endDate.value,
        q: q.value,
        status: status.value,
        type: type.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/synchrons-msc', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          items.forEach((item, i) => {
            item.key = i
          })
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }
    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/synchrons-msc', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `log-data-msc_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    const syncData = () => {
      // const _params = {
      //   from: moment(sync_start_date.value).format(dateFormat),
      //   to: moment(sync_end_date.value).format(dateFormat),
      //   function: syncFunction.value,
      //   type: syncType.value,
      // }
      state.isSync = true
      apiClient
        .post('/api/synchrons', {
          from: moment(sync_start_date.value).format(dateFormat),
          to: moment(sync_end_date.value).format(dateFormat),
          function: syncFunction.value,
          type: syncType.value,
        })
        .then(response => {
          const { items, _meta } = response.data
          var resultApi = document.getElementById('resultApi')
          resultApi.innerHTML = JSON.stringify(response.data, undefined, 2)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          state.isSync = false
        })
    }

    const setModal1Visible = visible => {
      modal1Visible.value = visible
    }
    const startRow = computed(() => (page.value - 1) * perPage.value + 1)
    watch(meta, (newValue, oldValue) => {
      page.value = newValue.currentPage
      perPage.value = newValue.perPage
    })
    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      isSync: false,
      isDownloading: false,
    })

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const onSearch = value => {
      findText.value = value
    }

    const syncrons = ({ id, trans_date }) => {
      Modal.confirm({
        title: 'Konfirmasi syncron data',
        content: 'Apakah anda ingin syncron data transaksi ?',
        onOk() {
          apiClient
            .post(`/api/synchrons/${id}/resynch`, { date: trans_date })
            .then(({ data }) => {
              console.log('response', data)
              modal1Visible.value = false
              fetchData()
              message.success('Berhasil disimpan')
            })
            .catch(e => message.error('Gagal syncron!'))
        },
        onCancel() {},
      })
    }

    return {
      modal1Visible,
      setModal1Visible,
      modal2Visible,
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
      perPage,
      totalCount,
      pageCount,
      page,
      meta,
      q,
      handleTableChange,
      startRow,
      // onSelectChange,
      state,
      fetchXlsx,
      fetchData,
      ...toRefs(state),
      begin_date,
      end_date,
      search,
      //  rowSelection,
      syncrons,
      status,
      type,
      errorMessage,
      disabledStartDate,
      disabledEndDate,
      firstDayMonth,
      lastDayMonth,
      dateFormat,
      formState,
      sync_start_date,
      sync_end_date,
      syncFunction,
      syncType,
      onSearch,
      syncData,
    }
  },
})
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>

<style scoped>
.myform :deep(.ant-input-disabled),
.myform :deep(.ant-input[disabled]),
.myform
  :deep(.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  /* color: #595c97 !important; */
  color: black;
  background-color: white;
  border: 0;
}
/* .ant-select-selection-placeholder */
.myform :deep(.ant-form-item) {
  margin-bottom: initial;
}
.myform :deep(.ant-select-disabled .ant-select-selection-placeholder),
.myform :deep(input.ant-input.ant-input-disabled::placeholder) {
  display: none;
  color: transparent;
}
.myform-vertical :deep(.ant-form-item-label > label) {
  font-size: 12px;
  margin: 0;
}
.myform-vertical :deep(.ant-form-item-label) {
  line-height: 1;
  padding: 0;
}
.myform :deep(div:not(.has-error) > .ant-form-explain) {
  color: #262d3d;
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
